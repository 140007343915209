/* PILLS */

.type-cell {
    padding: 5px;
    border-radius: 25px;
    width: fit-content;
    padding: 5px 15px;
    font-weight: bold;
    margin: 0 3px;
    cursor: default;
    /* Add other common styles for the "Type" cell */
  }
  
  /* Additional styles for different types */
  .type-cell.Regular {
    background-color: #449600;
    color: white;
    padding: 8px;
  }
  
  .type-cell.LGBT {
    background-color: #ffdf00;
    color: black;
    padding: 8px;
  }
  .type-cell.Senior {
    background-color: #10069f;
    color: white;
    padding: 8px;
  }
  
  .type-cell.PWD {
    background-color: #e4002b;
    color: white;
    padding: 8px;
  }
  
  /*.type-cell.Overstocked {
    background-color: #00ffdd;
    color: white;
    padding: 8px;
  }*/
  .type-cell.Healthy {
    background-color: #00ff15;
    color: white;
    padding: 8px;

  }
  .type-cell.Critical {
    background-color: #fffb00;
    color: black;
    padding: 8px;
  }
  /*.type-cell.AtCritical {
    background-color: #ff7b00;
    color: white;
    padding: 8px;
  }*/
  .type-cell.Severe {
    background-color: #ff0000;
    color: white;
    padding: 8px;
  }
  .type-cell.Unavailable{
    background-color: #000000;
    color: white;
    padding: 8px;
  }

  .type-cell.Administrator{
    background-color: #272727;
    color: white;
    padding: 8px;
  }
  .type-cell.Jail{
    background-color: #666666;
    color: white;
    padding: 8px;
  }
  .type-cell.Concessionaire {
    background-color: #b1b1b1;
    color: rgb(0, 0, 0);
    padding: 8px;
  }


  .type-cell.False{
    background-color: #ed4337;
    color: white;
    padding: 8px;
  }
  
  .type-cell.True{
    background-color: #4bb543;
    color: white;
    padding: 8px;
  }