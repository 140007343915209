/* Modals */

.modal {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh; /* Use the full height of the viewport */
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.4);
    z-index: 999 !important;
    opacity: 0; /* Start with opacity set to 0 */
    transition: all 0.3s ease-in-out; /* Transition the opacity property */
    backdrop-filter: blur(3px);
    position: fixed;
    visibility: visible;
  }
  
  .modal.closed {
    opacity: 0; /* Set opacity to 0 when closed */
    pointer-events: none; /* Disable pointer events when closed to prevent interactions */
    visibility: hidden;
    
  }
  
  .modal:not(.closed) {
    opacity: 1; /* Set opacity to 1 when open */
  }
  

  .modal-content {
    background: #fff;
    margin: auto; /* shorthand for margin-top: auto; margin-bottom: auto; */
    padding: 12px 24px;
    z-index: 4;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    width: fit-content;
    transition: all 0.3s ease-in-out;
    transform: translateY(0%)
  }
  
  .modal-content.closed{
    transform: translateY(-5%);
  }
  .modal-content:not(.closed) {
    transform: translateY(0%)
  }
  .modal-content label {
    text-align: left;
    font-size: 12px;
  } 
  
  .custom-modal-header {
    font-weight: 500;
    padding: 10px 0;
    font-size: 26px;
    width: 100%;
  }
  
  .custom-modal-header .modal-close {
    float: right;
    font-size: 20px;
    background: #ffffff;
    border: 0;
    outline: 0;
    border: 1px solid #ffffff;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .custom-modal-body {
    color: #2b2b2b;
  }
  
  .custom-modal-body label {
    text-align: left;
  }
  
  .custom-modal-footer {
    padding: 5px 0;
  }
  
  .custom-modal-footer .modal-close {
    font-size: 14px;
    background-color: #fff;
    border: 1px solid #333;
    outline: none;
    border-radius: 5px;
    color: #505050;
    cursor: pointer;
    display: inline-block;
    text-align: center;
  }
  
  .reminder-message{
    font-size: 12px;
  }