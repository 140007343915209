/* Side Navigation Bar */
.sidebar {
    min-height: 100vh;
    width: 78px;
    padding: 6px 14px;
    z-index: 60;
    transition: all .5s ease;
    position: fixed;
    top: 0;
    left: 0;
    font-family: 'Tahoma';
    background-color: white;
  }
  

  .sidebar-small {
    width: 78px;
    padding: 6px 14px;
    z-index: 60;
    transition: all .5s ease;
    top: 0;
    left: 0;
    font-family: 'Tahoma';
    background-color: white;
  }
  
  .sidebar.open {
    width: 250px;
  }
  
  
  
  .sidebar .logo-details, .sidebar-small .logo-details {
    height: 60px;
    display: flex;
    align-items: center;
    position: relative;

  }
  
  .sidebar .logo-details .icon, .sidebar-small .logo-details .icon{
    opacity: 0;
    transition: all 0.5s ease;
  }
  .sidebar.open .logo-details .icon,
  .sidebar.open .logo-details .logo-name {
    opacity: 1;
    display: block;
  }
  
  
  .sidebar .logo-details .logo-name, .sidebar-small .logo-details .logo-name {
    color: #646F85;
    font-size: 13px;
    font-weight: 600;
    opacity: 0;
    transition: all .5s ease;
    display: none;
    font-family: 'Tahoma';
    text-align: left;
    margin-left: 10px;
    white-space: collapse;
    overflow: hidden;
  }

  
  .sidebar .logo-details #btn, .sidebar-small .logo-details #btn {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    font-size: 23px;
    text-align: center;
    cursor: pointer;
    transition: all .5s ease;
  }
  
  .sidebar.open .logo-details #btn {
    text-align: right;
  }
  
  .logo {
    width: 45px;
    height: 45px;
  }
  
  .sidebar i, .sidebar-small i {
    color: #646F85;
    ;
    height: 60px;
    line-height: 60px;
    min-width: 50px;
    font-size: 25px;
    text-align: center;
  }
  
  .sidebar .nav-list, .sidebar-small .nav-list {
    margin-top: 20px;
    height: 100%;
  }
  
  .sidebar li, .sidebar-small li {
    position: relative;
    margin: 8px 0;
    list-style: none;
  }
  
  .sidebar li .tooltip, .sidebar-small li .tooltip {
    position: absolute;
    top: -20px;
    left: calc(100% + 15px);
    z-index: 61;
    background-color: white;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.3);
    padding: 6px 14px;
    font-size: 15px;
    font-weight: 400;
    border-radius: 5px;
    white-space: nowrap;
    opacity: 0;
    pointer-events: none;
    color: #555;
  }
  
  .sidebar li:hover .tooltip, .sidebar-small li:hover .tooltip {
    opacity: 1;
    pointer-events: auto;
    transition: all 0.4s ease;
    top: 50%;
    transform: translateY(-50%);
    
  }
  
  .sidebar.open li .tooltip {
    display: none;
  }
  
  .sidebar input {
    font-size: 15px;
    color: var(--color-white);
    font-weight: 400;
    outline: none;
    height: 35px;
    width: 35px;
    border: none;
    border-radius: 5px;
    background-color: var(--color-second);
    transition: all .5s ease;
  }
  
  .sidebar input::placeholder {
    color: var(--color-light)
  }
  
  .sidebar.open input {
    width: 100%;
    padding: 0 20px 0 50px;
  }
  
  .sidebar .bx-search {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    font-size: 22px;
    background-color: var(--color-second);
    color: var(--color-white);
  }
  
  .sidebar li a,
  .sidebar li button,
  .sidebar-small li a,
  .sidebar-small li button {
    display: flex;
    height: 100%;
    width: 100%;
    align-items: center;
    text-decoration: none;
    background-color: white;
    position: relative;
    transition: all .5s ease;
    z-index: 12;
    border: none;
    padding: 0;
  }
  
  .sidebar li a::after,
  .sidebar li button::after,
  .sidebar-small li a::after,
  .sidebar-small li button::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    transform: scaleX(0);
    background-color: #646F85;
    border-radius: 5px;
    transition: transform 0.3s ease-in-out;
    transform-origin: left;
    z-index: -2;
  }
  
  .sidebar li a.active,
  .sidebar li button.active,
  .sidebar-small li a.active,
  .sidebar-small li button.active {
    color: white;
    background-color: #646F85;
    border-radius: 5px;
    
  }
  
  
  .sidebar li a:hover::after,
  .sidebar li button:hover::after, 
  .sidebar-small li a:hover::after,
  .sidebar-small li button:hover::after {
    transform: scaleX(1);
    color: var(--color-default)
  }
  
  .sidebar ul,
  .sidebar-small ul{
    padding: 0;
    margin-top: 20px;
  }
  
  .sidebar li a .link-name,
  .sidebar li button .link-name,
  .sidebar-small li a .link-name,
  .sidebar-small li button .link-name {
    color: #646F85;
    ;
    font-size: 15px;
    font-weight: 400;
    white-space: nowrap;
    pointer-events: auto;
    transition: all 0.4s ease;
    pointer-events: none;
    opacity: 0;
  }
  
  .sidebar li a.active .link-name ,
  .sidebar li a.active i,
  .sidebar li button.active .link-name ,
  .sidebar li button.active i,
  .sidebar-small li a.active .link-name ,
  .sidebar-small li a.active i,
  .sidebar-small li button.active .link-name ,
  .sidebar-small li button.active i {
    color: white;
  }
  .sidebar li a.active .link-name:hover ,
  .sidebar li a.active i:hover,
  .sidebar li button.active .link-name:hover ,
  .sidebar li button.active i:hover,
  .sidebar-small li a.active .link-name:hover ,
  .sidebar-small li a.active i:hover,
  .sidebar-small li button.active .link-name:hover ,
  .sidebar-small li button.active i:hover {
    color: white;
  }
  
  .sidebar li a:hover .link-name,
  .sidebar li a:hover i,
  .sidebar li button:hover .link-name,
  .sidebar li button:hover i,
  .sidebar-small li a:hover .link-name,
  .sidebar-small li a:hover i,
  .sidebar-small li button:hover .link-name,
  .sidebar-small li button:hover i  {
    transition: all 0.5s ease;
    color: white;
  }
  
  .sidebar.open li a .link-name,
  .sidebar.open li button .link-name {
    opacity: 1;
    pointer-events: auto;
  }
  
  .sidebar li i {
    height: 35px;
    line-height: 35px;
    font-size: 18px;
    border-radius: 5px;
  }
  
  .sidebar li.profile {
    position: fixed;
    height: 60px;
    width: 78px;
    left: 0;
    bottom: -8px;
    padding: 10px 14px;
    overflow: hidden;
    transition: all .5s ease;
  }
  
  .sidebar.open li.profile {
    width: 250px;
  }
  
  .sidebar .profile .profile_details {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
  }
  
  .sidebar li img {
    height: 45px;
    width: 45px;
    object-fit: cover;
    border-radius: 50%;
    margin-right: 10px;
  }
  
  .sidebar li.profile .name,
  .sidebar li.profile .designation {
    font-size: 15px;
    font-weight: 400;
    color: var(--color-white);
    white-space: nowrap;
  }
  
  .sidebar li.profile .designation {
    font-size: 12px;
  }
  
  .sidebar .profile #log_out {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    background-color: var(--color-second);
    width: 100%;
    height: 60px;
    line-height: 60px;
    border-radius: 5px;
    cursor: pointer;
    transition: all 0.5s ease;
  }
  
  .sidebar.open .profile #log_out {
    width: 50px;
    background: none;
  }
  
  .home-section {
    position: relative;
    background-color: var(--color-body);
    min-height: 100vh;
    top: 0;
    left: 78px;
    width: calc(100% - 78px);
    transition: all .5s ease;
    z-index: 2;
  }
  
  .home-section .text {
    display: inline-block;
    color: var(--color-default);
    font-size: 25px;
    font-weight: 500;
    margin: 18px;
  }
  
  .sidebar.open~.home-section {
    left: 250px;
    width: calc(100% - 250px);
  }

  .bottom-links {
    position: absolute;
    width: 100%;
    bottom: 0;
    left: 0;
    padding: 10px 15px; /* Adjust as needed */
    box-sizing: border-box;
  }
  
  .bottom-links li {
    margin-bottom: 10px; /* Adjust as needed */
  }
  