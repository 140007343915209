.purchase-modal-text h6{
    font-size: 14px;
    font-weight: bold;
}

.purchase-modal-text p{
    font-size: 14px;
}

.purchase-modal-pdl{
    background-color: #10069f;
    color: white;
    padding: 20px;
    border-radius: 10px;
    height: 100%;
}

.purchase-modal-pdl .text-input, .purchase-modal-pdl select, .purchase-modal-pdl .text-input:disabled{
    background-color: transparent;
    color: white;
    border: 1px solid #fff;
    border-radius: 10px;
    padding: 5px 10px;
    width: 100%;
    appearance: none;
    -webkit-appearance: none; 
    -moz-appearance: none; 
    background-image: none; 
}

.pdl-modal-option{
    background-color: #10069f;
    color: white;
    border: none;
    border-radius: 10px;
    padding: 10px 0;
}
.purchase-modal-pdl .custom-select-wrapper {
    position: relative;
    display: inline-block;
    width: 100%; 
}
.purchase-modal-pdl .custom-select-wrapper::after {
    content: '▼';
    position: absolute;
    top: 50%;
    right: 10px; 
    transform: translateY(-50%);
    pointer-events: none;
    color: white; 
    font-size: 12px;
}

.purchase-modal-pdl .custom-select-wrapper select {
    width: 100%;
    box-sizing: border-box; 
    background-color: transparent;
    color: white;
    border: 1px solid #fff;
    border-radius: 10px;
    padding: 5px 10px;
    appearance: none; 
    -webkit-appearance: none; 
    -moz-appearance: none; 
    background-image: none;
}

.purchase-modal-pdl input::placeholder{
    color: white;
}

.purchase-modal-pdl h4{
    font-weight: bold;
}