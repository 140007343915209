

.pos-button-border{
    border: 1px solid #ccc;
    border-radius: 15px;
    text-align: center;
    box-shadow: 6px 4px 10px rgba(0, 0, 0, 0.15);
    transition: all 0.3s ease-in-out;
    cursor: pointer;
    overflow: hidden;
    margin: 6px;
}


.pos-button-image{
    border-radius: 100px;
}

.pos-button-image-container{
    border-radius: 25px;
}

.pos-button-border p{
    margin: 0;
    font-size: 10px;
    cursor: pointer;
}

.pos-button-border h6{
    font-weight: bold;
    font-size: 12px;
    cursor: pointer;
}

.pos-button-border:hover{
    transform: scale(1.05);
}

.pos-button-border span{
    border-radius: 5px;
    padding: 4px;
    color: white;
    font-size: 9px;
}

.pos-button-border > .price{
    float: left;
    background-color: blue;
}

.pos-button-border > .stock{
    float: right;
    background-color: green;
}

.pos-cursor-circle {
    position: absolute;
    width: 30px;
    height: 30px; 
    background-color: rgba(0, 0, 0, 0.05); 
    border-radius: 50%; 
    transition: transform 0.2s ease-in-out;
  }

  .toggle-checkout-button{
    border: none;
    background-color: white;

  }

  .pos-empty-cell{
    border: 1px dashed #ccc;
    border-radius: 15px;
    padding: 85px 0;
    margin: 8px;
  }
  .pos-empty-cell-small{
    border: 1px dashed #ccc;
    border-radius: 15px;
    padding: 65px 0;
    margin: 8px;
}
  .pos-button-container, .pos-button-input{
    font-size: 12px;
  }

  @media screen and (max-width: 1366px)  {
    .pos-empty-cell-small{
        padding: 80px 0;
      }
  }