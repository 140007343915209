/* Top Navigation Bar */

.topnav {
    position: fixed;
    top: 0;
    background-color: white;
    height: 60px;
    z-index: 20;
    width: stretch;
    box-shadow: 6px 4px 10px rgba(0, 0, 0, 0.1);
    transition: all .5s ease;
    padding: 0 15px;
  }
  
  .topnav.open{
    margin-left: 230px;
  }

  .topnav.close{
    margin-left: 58px;
  }

  .topnav a {
    float: left;
    color: black;
    text-align: center;
    padding: 14
    px 16px;
    text-decoration: none;
    font-size: 17px;
  }
  
  .topnav a:hover {
    background-color: lightblue;
    color: black;
  }
  
  .topnav a.Home {
    background-color: lightblue;
    color: black;
  
  }
  
  .topnav .search-container {
    float: left;
    position: relative;
    left: 20px;

  
  }
  
  .topnav input[type=text] {
    padding: 12px;
    margin-top: 8px;
    font-size: 17px;
    border: none;
  }
  
  .topnav .search-container button {
    float: right;
    padding: 12px 10px;
    margin-top: 8px;
    margin-right: 16px;
    background: #ddd;
    font-size: 17px;
    border: none;
    cursor: pointer;
  }
  
  .topnav .search-container button:hover {
    background: #ccc;
  }
  
  @media screen and (max-width: 600px) {
    .topnav .search-container {
      float: none;
    }
  
    .topnav a,
    .topnav input[type=text],
    .topnav .search-container button {
      float: none;
      display: block;
      text-align: left;
      width: 100%;
      margin: 0;
      padding: 14px;
    }
  
    .topnav input[type=text] {
      border: 1px solid #ccc;
    }
  }