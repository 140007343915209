/* TABLES */

.table-container {
    width: 100%;
    height: 100%;
    border-radius: 8px;
    background-color: #ffffff;
    box-shadow: 6px 4px 10px rgba(0, 0, 0, 0.25);
    position: relative;
  }
.table-container-small {
    width: 100%;
    height: 100%;
    border-radius: 8px;
    background-color: #ffffff;
    box-shadow: 6px 4px 10px rgba(0, 0, 0, 0.25);
    position: relative;
    transition: all 0.2s ease-in-out;
  }

  .table-container-small:hover{
    transform: scale(1.03);
  }
  .table-button {
    border-radius: 8px;
    background: none;
    border: 1px solid #999;
    height: 38px;
    justify-content: center;
    align-items: center;
    color: #999;
    flex-shrink: 0;
    position: relative;
    font-size: 14px;
    left: 55%;
    top: 18%;
    padding: 0 20px;
    transition: all 0.3s ease-in-out;
  }
  
  .table-button:hover{
    background-color: #eee;
  }
  .icon-tooltip {
    position: absolute;
    display: block;
    background-color: #333;
    color: #fff;
    padding: 5px;
    border-radius: 4px;
    font-size: 14px;
    opacity: 0;
    z-index: 99;
    width: max-content;
    cursor: default;
    transition: opacity 0.3s ease-in-out; /* Add a fade-in transition */
  }
  
  .icon-hover:hover + .icon-tooltip {
    opacity: 1;
  }

  .icon-large{
    font-size: 20px;
  }
  .clean-table {
    width: stretch;
    border-top: 2px solid #ddd; /* Border on top */
    border-bottom: 2px solid #ddd; /* Border on bottom */
    height: auto;
    border-collapse: collapse;
    color: #48505E;
    overflow: auto;
    font-size: 14px;
  }
  
  .clean-table th{
    padding: 12px;
    text-align: left;
  }

  .selectable-table .selected-row, .table-button-active, .toggle-form-button .active {
    background-color: #0099ff;
    color: white;
    border: none;
  }
  .selectable-table .selected-row i {
    color: white;
  }
  .selectable-table tbody tr {
    transition: transform 0.2s ease-in-out;
  }
  
  .selectable-table tbody tr:hover {
    transform: scale(1.01);
  }

  .zebra-even {
    background-color: #f9f9f9; /* Even row background color */
  }
  
  .zebra-odd {
    background-color: #fff; /* Odd row background color */
  }
  .action-buttons{
    text-align: center;
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .action-buttons p{
    margin-right: 5px;
    cursor: pointer;
    margin-bottom: 0;
    font-size: 16px;
  }

.table-button-active:hover{
    background-color: #005f9e;
    color: white;
}
.filter-dropdown {
    position: absolute;
    z-index: 96;
    background-color: #fff;
    border: 1px solid #ccc;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    padding: 8px;
    top: 0;
    transform: translateY(100px);
  }
  
  .filter-option {
    padding: 3px;
    font-size: 14px;
    color: #333;
    transition: background-color 0.3s ease;
    cursor: pointer;
  }
  
  .filter-option:hover {
    background-color: #f0f0f0;
  }

  .filter-icon{
    color: #333;
    transition: all 0.2s ease-in-out;
  }
  .filter-icon:hover{
    color: #555;
    transform: scale(1.05);
  }

  .table-display-empty{
    text-align: center;
  }
  .table-display-empty-padding{
    padding: 0;
  }
  .table-display-empty-modal{
    text-align: center;
  }
  .table-display-empty-padding-modal{
    padding: 5rem 8rem;
  }
  .table-display-empty-hover{
    transition: all 0.5s ease-in-out;
  }

  .table-display-empty-hover:hover{
    transform: scale(1.02);
  }
  .pagination {
    display: flex;
    overflow-x: auto; /* Adds horizontal scroll if items overflow */
    white-space: nowrap; /* Prevents wrapping */
    padding: 0;
    margin: 0;
    list-style: none;
}

  .pagination-container {
      max-width: 100%; /* Ensures it stays within its parent */
      overflow-x: hidden; /* Optional: Prevents spilling beyond the parent container */
  }
  .pagination-link {
    background-color: white;
    border: 1px solid #ccc;
    padding: 4px 8px;
    transition: all 0.3s ease-in-out;
    margin: 0;
    font-size: 14px;
  }
  .pagination-link:hover {
    background-color: rgb(230, 230, 230);
  }
  .pagination-link-active{
    background-color: #48505E;
    color: white;
    border: 1px solid #ccc;
    margin: 0;
    padding: 4px 8px;
    transition: all 0.3s ease-in-out;
    
  }
  .pagination-link-active:hover{
    background-color: #363d47;
    color: white;
  }
  .pagination-info{
    font-size: 12px;
  }
.table-overflow{
  overflow: auto;
}
  
