.pos-table-container {
    width: 100%;
    height: 100%;
    border-radius: 8px;
    background-color: #ffffff;
    box-shadow: 6px 4px 10px rgba(0, 0, 0, 0.25);
    position: relative;
  }

  .pos-selector {
    max-height: 100vh; 
    overflow-y: auto;
    display: flex;
    align-items: center;
    position: relative;
    overflow: auto;
    flex-wrap: wrap;
  }

  .pos-page-container{
    display: flex;
  }
  .checkout-margin {
    margin: 0px 30px;
  }
  @media screen and (max-width: 1366px) { 
    .pos-selector {
        flex-wrap:initial;
    }

    .pos-page-container{
        display: block;
    }

    .checkout-margin {
        margin: 30px 0px;
    }
}


.blue-button{
    background-color: #10069f;
    color: white;
}

.blue-button:hover{
    background-color: #3428d8;
}

.white-text{
  color: white;
}