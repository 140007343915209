body{
    background-color: #f9f9f9;
    padding: 20px;
}

.load-container, .profile-container{
    background-color: white;
    border-radius: 8px;
    background-color: #ffffff;
    box-shadow: 6px 4px 10px rgba(0, 0, 0, 0.25);
    position: relative;
}


.load-form label, .profile-container label{
    font-size: 12px;
}

.pdl-selector{
    background-color: white;
    border: 1px solid #ccc;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px 0 ;
    text-align: center;
}


.search-bar{
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
}

.search-button{
    background-color: #505050;
    color: white;
    border: none;
    padding: 7px 10px;
    margin: 0;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    transition: all 0.2s ease-in-out;
}

.search-button:hover{
    background-color: #777777;
}

.under-construction-notice{
    text-align: center;
}

.under-construction-notice h4{
    font-weight: bold;
    font-size: 22px;
}

.under-construction-notice p{
    font-weight: lighter;
    font-size: 14px;
}

.autocomplete {
    position: absolute;
    background-color: white;
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    text-align: left;
}

.list-none{
    list-style: none;
    font-size: 11px;
}

.patch-notes-block{
    max-height: 150px;
    overflow-y: scroll;
}

.fingerprint-icon, .fingerprint-icon-scanning,
.fingerprint-icon-success, .fingerprint-icon-error{
    color: white;
    padding: 15px;
    border-radius: 50%;
    font-size: 40px;
    transition: 0.3s all ease-in-out;
    cursor: pointer;
}

.fingerprint-icon{
    background-color: black;
}

.fingerprint-icon-scanning{
    background-color: #21008f;
    animation: bounce 1s infinite;
}

.fingerprint-icon-success{
    background-color: #00b800;
}

.fingerprint-icon-error{
    background-color: red;
}

.fingerprint-icon:hover,
.fingerprint-icon-scanning:hover,
.fingerprint-icon-success:hover,
.fingerprint-icon-error:hover{
    transform: scale(1.15);
}
.fingerprint-icon::after, .fingerprint-icon-scanning::after,
.fingerprint-icon-success::after, .fingerprint-icon-error::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(255, 255, 255, 0.2); /* Light color overlay */
    border-radius: 50%;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
}

.fingerprint-icon:hover::after,
.fingerprint-icon-scanning:hover::after,
.fingerprint-icon-success:hover::after,
.fingerprint-icon-error:hover::after {
    opacity: 1;
}

@keyframes bounce {
    0%, 20%, 50%, 80%, 100% {
        transform: scale(1);
    }
    40% {
        transform: scale(1.1);
    }
    60% {
        transform: scale(1.05);
    }
}