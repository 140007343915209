/* Buttons */

.main-btn {
    background-color: #505050;
    border: none;
    padding: 8px 20px;
    border-radius: 5px;
    color: white;
    transition: all 0.2s ease-in-out;
  }

.main-btn-red {
    background-color: #ff3333;
    border: none;
    padding: 8px 20px;
    border-radius: 5px;
    color: white;
    transition: all 0.2s ease-in-out;
}

.main-btn:hover{
    background-color: #777777;
}
.main-btn:disabled{
    background-color: #d1d1d1;
    color: black;
}
.main-btn-red:hover {
    background-color: #c52222;

}

.link-btn{
    border: 1px solid #ffffff;
    background-color: transparent;
    padding: 8px 20px;
    border-radius: 5px;
    transition: all 0.3s ease-in-out;
}

.link-btn:hover{
    text-decoration: underline;
}

.toggle-form-button{
    border-radius: 5px;
    border: 1px solid #ccc;
    background-color: white;
    margin-top: 32px;
    padding: 5px 7px;
    transition: all 0.4s ease-in-out;
}
.toggle-form-button:hover{
    background-color: #eeeeee;
}
.toggle-form-button-active{
    border-radius: 5px;
    border: 1px solid #ccc;
    background-color: #0099ff;
    color: white;
    margin-top: 32px;
    padding: 5px 7px;
    transition: all 0.4s ease-in-out;
}
.toggle-form-button-active:hover{
    background-color: #47b5ff;
}
.item-modal-field-state-button{
    transition: all 0.4s ease-in-out;
}

.profile-button{
    background-color: transparent;
    border: none;
    transition: all 0.4s ease-in-out;
}

.profile-button:hover{
    color: #777777;
}

.pay-now-button{
    color: white;
    background-color: #10069f;
    border: 1px solid white;
    padding: 10px 20px ;
    border-radius: 20px;
    transition: all 0.4s ease-in-out;
}

.pay-now-button:hover{
    background-color: #2014cc;
}