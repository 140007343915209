.checkout-bar {
    min-height: 100vh;
    width: 700px;
    z-index: 15;
    transition: all .5s ease-in-out;
    position: fixed;
    top: 0;
    right: 0;
    background-color: white;
    padding: 20px;
    padding-top: 100px;
    transform: translateX(var(--checkout-bar-width, 0px));
    box-shadow: -3px 4px 10px rgba(0, 0, 0, 0.15);
}


.button-icon-rotate{
    transition: all 0.5s ease-in-out;
    transform: translateX(-50px);
    background-color: white;
    padding: 20px 10px;
    border-radius: 10px;
    z-index: 10;
}

.checkout-list{
    background-color: #f8f8f8;
    border-radius: 10px;
    padding: 20px;
    height: 60vh;
    overflow-y: auto;
    overflow-x: hidden;
}

.checkout-list-item{
    background-color: white;
    padding: 10px 30px;
    border-radius: 20px;
    box-shadow: 6px 4px 10px rgba(0, 0, 0, 0.15);
    transition: all 0.4s ease-in-out;
}

.checkout-list-item:hover{
    transform: scale(1.03);
}

.checkout-list-item h6{
    font-weight: bold;
    font-size: 14px;
}

.checkout-list-item p{
    margin: 0;
    font-size: 12px;
}